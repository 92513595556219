@import './font';
@import './colors';
@import './variables';

html,
body * {
  font-family: $font-roboto;
}

.isDisabledClass {
  pointer-events: none !important;
  a {
    color: $disabled-gray !important;
  }
}

.customLink {
  color: $light-blue;
  font-size: 14px;
  cursor: pointer;
}

.text-underline {
  text-decoration: underline;
}

.required {
  color: $error;
}

// margin
.m5 {
  margin: 5px;
}
.m10 {
  margin: 10px;
}
.m15 {
  margin: 15px;
}
.m20 {
  margin: 20px;
}
.m25 {
  margin: 25px;
}

// margin-top
.mt0 {
  margin-top: 0;
}
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt25 {
  margin-top: 25px;
}

// margin-right
.mr0 {
  margin-right: 0;
}
.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.mr15 {
  margin-right: 15px;
}
.mr20 {
  margin-right: 20px;
}
.mr25 {
  margin-right: 25px;
}

// margin-bottom
.mb0 {
  margin-bottom: 0;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb25 {
  margin-bottom: 25px;
}

// margin-left
.ml0 {
  margin-left: 0;
}
.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
.ml25 {
  margin-left: 25px;
}

// padding
.p0 {
  padding: 0px;
}
.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}
.p25 {
  padding: 25px;
}

// padding-top
.pt0 {
  padding-top: 0;
}
.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt15 {
  padding-top: 15px;
}
.pt20 {
  padding-top: 20px;
}
.pt25 {
  padding-top: 25px;
}

// padding-right
.pr0 {
  padding-right: 0;
}
.pr5 {
  padding-right: 5px;
}
.pr10 {
  padding-right: 10px;
}
.pr15 {
  padding-right: 15px;
}
.pr20 {
  padding-right: 20px;
}
.pr25 {
  padding-right: 25px;
}

// padding-bottom
.pb0 {
  padding-bottom: 0;
}
.pb5 {
  padding-bottom: 5px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb15 {
  padding-bottom: 15px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb25 {
  padding-bottom: 25px;
}

// padding-left
.pl0 {
  padding-left: 0;
}
.pl5 {
  padding-left: 5px;
}
.pl10 {
  padding-left: 10px;
}
.pl15 {
  padding-left: 15px;
}
.pl20 {
  padding-left: 20px;
}
.pl25 {
  padding-left: 25px;
}

.bottom-hr {
  border-bottom: 1px solid $light-gray;
}

// flex-box helpers
.d-flex {
  display: flex;
}

.justify-content {
  &-start {
    justify-content: flex-start;
  }

  &-end {
    justify-content: flex-end;
  }

  &-center {
    justify-content: center;
  }

  &-between {
    justify-content: space-between;
  }
}

.align-items {
  &-start {
    align-items: start;
  }

  &-end {
    align-items: end;
  }

  &-center {
    align-items: center;
  }
}
