@import "src/app/scss/colors";
@import "src/app/scss/variables";

.wrap {
  display: flex;
  align-items: center;
  .icon {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    display: flex;
    span {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 100%;
        height: 100%;
        fill: $lime;
      }
    }
  }

  .name {
    font-weight: bold;
    margin-right: 5px;
  }

  .text {

  }
}