@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;515;600;700&display=swap);
.loginInfo_main__uC06f{position:relative;width:140px;height:64px;padding:10px;border-bottom:2px solid #fff;font-size:14px;text-align:center;cursor:pointer}.loginInfo_main__name__1MK-Z{color:#000}.loginInfo_main__permission__2mpG9{color:rgba(0,0,0,0.45098);font-size:12px}.loginInfo_main__uC06f:hover{border-bottom:2px solid #039BE6}.loginInfo_main__uC06f:hover .loginInfo_main__name__1MK-Z{color:#039BE6}.loginInfo_main__uC06f:hover .loginInfo_wrap__2WQuK{visibility:visible}.loginInfo_main__uC06f:hover .loginInfo_dropdown__1BE4m{opacity:1}.loginInfo_wrap__2WQuK{visibility:hidden;position:absolute;top:64px;right:0;padding-top:4px;z-index:1}.loginInfo_dropdown__1BE4m{position:relative;width:270px;opacity:0;background:#fff 0 0 no-repeat padding-box;box-shadow:8px 8px 8px rgba(0,21,41,0.121569);border-radius:2px;text-align:left;transition:all 0.3s ease-in-out}.loginInfo_dropdown__user__3xqQd{padding:20px;color:#000;border-bottom:1px solid rgba(204,204,204,0.85098);font-size:16px}.loginInfo_dropdown__user_email__2Fpbb{color:rgba(0,0,0,0.45098);font-size:12px}.loginInfo_dropdown__actions__3sV6S{margin:0;padding:5px;list-style:none}.loginInfo_dropdown__actions_item__2Xwsk{display:block;height:30px;padding:5px 15px;margin-bottom:5px;background:#fff;font-size:14px}.loginInfo_dropdown__actions_item__2Xwsk:hover{background:#FAFAFA;color:rgba(0,0,0,0.45098)}.loginInfo_dropdown__actions_item__2Xwsk:last-child{margin-bottom:0}.loginInfo_dropdown__actions_item__2Xwsk[aria-disabled=true]{color:rgba(204,204,204,0.85098)}.loginInfo_dropdown__actions_item__2Xwsk[aria-disabled=true]:hover{background:#fff;pointer-events:none}.loginInfo_dropdown__actions_logout__3KWd-{color:rgba(255,82,83,0.85098)}.loginInfo_dropdown__actions_logout__3KWd-:hover{background:rgba(255,82,83,0.85098);color:#fff}

.header_wrap__2hM0d{display:flex;align-items:flex-start;justify-content:space-between;height:64px;padding:0 50px;background:#fff;box-shadow:0 1px 4px #0015291F;color:rgba(0,0,0,0.65)}

.styles_text_container__1faJN{color:#7A8185;padding-left:27px}

.styles_text-container__ajMEv{color:#7A8185}

.styles_wrap__38SvY{display:flex;align-items:center}.styles_wrap__38SvY .styles_icon__3NSF_{margin-right:10px;width:20px;height:20px;display:flex}.styles_wrap__38SvY .styles_icon__3NSF_ span{width:100%;height:100%;display:flex;align-items:center;justify-content:center}.styles_wrap__38SvY .styles_icon__3NSF_ span svg{width:100%;height:100%;fill:#73D13D}.styles_wrap__38SvY .styles_name__LfeGo{font-weight:bold;margin-right:5px}

.loader_spinner__35QXJ{overflow:hidden;position:fixed;display:flex;left:0;top:0;align-items:center;justify-content:center;z-index:10000;background-color:rgba(0,0,0,0.35);height:100%;width:100%}.loader_spinner__35QXJ .anticon svg{width:4rem;height:4rem}

html,body *{font-family:Roboto,Comfortaa,Arial,sans-serif}.isDisabledClass{pointer-events:none !important}.isDisabledClass a{color:#ccc !important}.customLink{color:#039BE6;font-size:14px;cursor:pointer}.text-underline{text-decoration:underline}.required{color:#ff4d4f}.m5{margin:5px}.m10{margin:10px}.m15{margin:15px}.m20{margin:20px}.m25{margin:25px}.mt0{margin-top:0}.mt5{margin-top:5px}.mt10{margin-top:10px}.mt15{margin-top:15px}.mt20{margin-top:20px}.mt25{margin-top:25px}.mr0{margin-right:0}.mr5{margin-right:5px}.mr10{margin-right:10px}.mr15{margin-right:15px}.mr20{margin-right:20px}.mr25{margin-right:25px}.mb0{margin-bottom:0}.mb5{margin-bottom:5px}.mb10{margin-bottom:10px}.mb15{margin-bottom:15px}.mb20{margin-bottom:20px}.mb25{margin-bottom:25px}.ml0{margin-left:0}.ml5{margin-left:5px}.ml10{margin-left:10px}.ml15{margin-left:15px}.ml20{margin-left:20px}.ml25{margin-left:25px}.p0{padding:0px}.p5{padding:5px}.p10{padding:10px}.p15{padding:15px}.p20{padding:20px}.p25{padding:25px}.pt0{padding-top:0}.pt5{padding-top:5px}.pt10{padding-top:10px}.pt15{padding-top:15px}.pt20{padding-top:20px}.pt25{padding-top:25px}.pr0{padding-right:0}.pr5{padding-right:5px}.pr10{padding-right:10px}.pr15{padding-right:15px}.pr20{padding-right:20px}.pr25{padding-right:25px}.pb0{padding-bottom:0}.pb5{padding-bottom:5px}.pb10{padding-bottom:10px}.pb15{padding-bottom:15px}.pb20{padding-bottom:20px}.pb25{padding-bottom:25px}.pl0{padding-left:0}.pl5{padding-left:5px}.pl10{padding-left:10px}.pl15{padding-left:15px}.pl20{padding-left:20px}.pl25{padding-left:25px}.bottom-hr{border-bottom:1px solid #E8E8E8}.d-flex{display:flex}.justify-content-start{justify-content:flex-start}.justify-content-end{justify-content:flex-end}.justify-content-center{justify-content:center}.justify-content-between{justify-content:space-between}.align-items-start{align-items:start}.align-items-end{align-items:end}.align-items-center{align-items:center}

