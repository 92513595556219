@import "src/app/scss/colors";

.wrap{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 64px;
  padding: 0 50px;
  background: $white;
  box-shadow: 0 1px 4px #0015291F;
  color: rgba(0, 0, 0, 0.65);
}