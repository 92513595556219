$dark: #000;
$dark-gray: #00000073;
$white: #fff;

$shadow-gray: #0015291F;
$text-gray: #2F333E;
$gray: #7A8185;
$ultramarine-gray: #CCCCCCD9;
$disabled-gray: #CCCCCC;
$light-gray: #E8E8E8;
$bg-gray: #FAFAFA;
$dim-gray: #EBEFF2;

$dark-blue: #002140;
$primary: #1890ff;
$light-blue: #039BE6;
$bg-blue: #E0F1F8;

$error: #ff4d4f;
$red: #FF5253D9;
$dim-red: #fdeded;

$lime: #73D13D;
$active-green: #2BC97C;

$sand: #FFCC32;