@import "src/app/scss/colors";

.main{
  position: relative;
  width: 140px;
  height: 64px;
  padding: 10px;
  border-bottom: 2px solid $white;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  &__name{
    color: $dark;
  }
  &__permission{
    color: $dark-gray;
    font-size: 12px;
  }
  &:hover{
    border-bottom: 2px solid $light-blue;
    .main__name{
      color: $light-blue;
    }
    .wrap {
      visibility: visible;
    }
    .dropdown{
      opacity: 1;
    }
  }
}
.wrap{
  visibility: hidden;
  position: absolute;
  top: 64px;
  right: 0;
  padding-top: 4px;
  z-index: 1;
}
.dropdown {
  position: relative;
  width: 270px;
  opacity: 0;
  background: $white 0 0 no-repeat padding-box;
  box-shadow: 8px 8px 8px $shadow-gray;
  border-radius: 2px;
  text-align: left;
  transition: all 0.3s ease-in-out;

  &__user{
    padding: 20px;
    color: $dark;
    border-bottom: 1px solid $ultramarine-gray;
    font-size: 16px;
    &_email{
      color: $dark-gray;
      font-size: 12px;
    }
  }

  &__actions{
    margin: 0;
    padding: 5px;
    list-style: none;
    &_item{
      display: block;
      height: 30px;
      padding: 5px 15px;
      margin-bottom: 5px;
      background: $white;
      font-size: 14px;
      &:hover{
        background: $bg-gray;
        color: $dark-gray;
      }
      &:last-child{
        margin-bottom: 0;
      }
      &[aria-disabled=true]{
        color: $ultramarine-gray;
        &:hover{
          background: $white;
          pointer-events: none;
        }
      }
    }
    &_logout{
      color: $red;
      &:hover{
        background: $red;
        color: $white;
      }
    }
  }
}